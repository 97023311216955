// extracted by mini-css-extract-plugin
export var post = "BlogPost-module--post--6LQxC";
export var rightNav = "BlogPost-module--right-nav--mRpzG";
export var postBody = "BlogPost-module--post-body--hinQo";
export var octicon = "BlogPost-module--octicon--xzeoa";
export var anchor = "BlogPost-module--anchor--9pbC-";
export var octiconLink = "BlogPost-module--octicon-link--yPaj9";
export var plC = "BlogPost-module--pl-c--DcNCP";
export var plC1 = "BlogPost-module--pl-c1--o1zzd";
export var plS = "BlogPost-module--pl-s--9jI7M";
export var plV = "BlogPost-module--pl-v--7otNS";
export var plE = "BlogPost-module--pl-e--PkEp+";
export var plEn = "BlogPost-module--pl-en--i+aYf";
export var plSmi = "BlogPost-module--pl-smi--Kz6hH";
export var plS1 = "BlogPost-module--pl-s1--xfnwf";
export var plEnt = "BlogPost-module--pl-ent--EvW+u";
export var plK = "BlogPost-module--pl-k--eA6jO";
export var plPds = "BlogPost-module--pl-pds--VCw45";
export var plPse = "BlogPost-module--pl-pse--FpFbV";
export var plSr = "BlogPost-module--pl-sr--I5SNZ";
export var plCce = "BlogPost-module--pl-cce--k5372";
export var plSre = "BlogPost-module--pl-sre--LThiw";
export var plSra = "BlogPost-module--pl-sra--+Y3Fk";
export var plSmw = "BlogPost-module--pl-smw--WJA+K";
export var plBu = "BlogPost-module--pl-bu--zNVx+";
export var plIi = "BlogPost-module--pl-ii--E1qpP";
export var plC2 = "BlogPost-module--pl-c2--qHOhe";
export var plMl = "BlogPost-module--pl-ml--bYQSm";
export var plMh = "BlogPost-module--pl-mh--8GlCw";
export var plMs = "BlogPost-module--pl-ms--0L0wb";
export var plMi = "BlogPost-module--pl-mi--L3pg1";
export var plMb = "BlogPost-module--pl-mb--0LcBJ";
export var plMd = "BlogPost-module--pl-md--CXs3A";
export var plMi1 = "BlogPost-module--pl-mi1--rq9ZS";
export var plMc = "BlogPost-module--pl-mc--PLTGf";
export var plMi2 = "BlogPost-module--pl-mi2--A9uor";
export var plMdr = "BlogPost-module--pl-mdr--IqqhO";
export var plBa = "BlogPost-module--pl-ba--+wSQj";
export var plSg = "BlogPost-module--pl-sg--KJ3rX";
export var plCorl = "BlogPost-module--pl-corl--8vpqC";
export var absent = "BlogPost-module--absent--vyyfL";
export var noList = "BlogPost-module--no-list--WW9A1";
export var emoji = "BlogPost-module--emoji--XzYz0";
export var frame = "BlogPost-module--frame--Idj0Y";
export var alignCenter = "BlogPost-module--align-center--t405A";
export var alignRight = "BlogPost-module--align-right--73QHm";
export var floatLeft = "BlogPost-module--float-left--YVxC6";
export var floatRight = "BlogPost-module--float-right--uu0YC";
export var highlight = "BlogPost-module--highlight--IO8Fw";
export var csvData = "BlogPost-module--csv-data--i41TJ";
export var blobNum = "BlogPost-module--blob-num--8M8qP";
export var footnotes = "BlogPost-module--footnotes--muW3U";
export var dataFootnoteBackref = "BlogPost-module--data-footnote-backref--mhVdk";
export var taskListItem = "BlogPost-module--task-list-item--KkS5+";
export var taskList = "BlogPost-module--task-list--2DCKY";
export var postBodyItem = "BlogPost-module--post-body-item--UsoQa";
export var enabled = "BlogPost-module--enabled--4dTT9";
export var handle = "BlogPost-module--handle--qvHOr";
export var taskListItemCheckbox = "BlogPost-module--task-list-item-checkbox--ypvCU";
export var containsTaskList = "BlogPost-module--contains-task-list--SFrt4";